<template>
    <div>
        <title-bar :title="$t('navigations.cwoLong')"/>
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="['search', 'addBtn', 'perPage', 'advancedFilter']"
                :addBtnText="$t('messages.cwoCreate')"
                :addBtnLink="$helper.getAddUrl('cwo')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:station="data">
                  <span v-if="data.value.station">
                    {{ data.value.station.name }}
                  </span>
                </template>
                <template v-slot:referenceNumber="data">
                    {{ data.value.referenceNumber }}
                </template>
                <template v-slot:customer="data">
                    {{ data.value.customer.customer }}
                </template>

                <template v-slot:acRegistration="data">
                    {{ data.value.acRegistration }}
                </template>

                <template v-slot:arrivalDate="data">
                    {{ $helper.formatDateTimeToUTC(data.value.arrivalDate) }}
                </template>

                <template v-slot:serviceRequested="data">
                    {{ data.value.serviceRequested }}
                </template>

                <template v-slot:estimatedManHours="data">
                    {{ formatHours(data.value.estimatedManHours) }}
                </template>

                <template v-slot:manHours="data">
                    {{ data.value.manHours }}
                </template>

                <template v-slot:deadline="data">
                    <b-badge :variant="deadlineColor(data.value.deadline)">
                        {{ $helper.formatDateTimeToUTC(data.value.deadline) }}
                    </b-badge>
                </template>

                <template v-slot:lastEdit="data">
                    {{ data.value.lastEdit.givenName }} {{ data.value.lastEdit.familyName }}
                </template>
                <template v-slot:status="data">
                    <b-badge :variant="statusClass(data.value.status)">{{ data.value.status }}</b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn :path="$helper.getEditUrl('cwo', data.value.id)"/>
                        <delete-btn @pressDelete="deleteOcr(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import {getUserData} from "@/auth/utils";

export default {
    name: "CwoListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            filterFields: [
                {
                    label: 'forms.deadline',
                    name: "deadline",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.acRegistration',
                    name: "acRegistration",
                    value: null,
                    type: "text",
                },
                {
                    label: 'forms.customer',
                    name: "customer",
                    value: null,
                    type: "customer"
                },
                {
                    label: 'forms.station',
                    name: "station",
                    value: null,
                    type: "entity",
                    entityName: "$Stations"
                },
                {
                    label: 'forms.closed',
                    name: "closedAt",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.lastEditBy',
                    name: "lastEdit",
                    value: null,
                    type: "employee"
                },
            ],
            advancedParams: [],
            sortable: {
                order: "status",
                sort: "asc",
            },
            columns: [
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.cwoNo"),
                    field: "referenceNumber",
                    sortable: true,
                },
                {
                    label: this.$t("forms.customer"),
                    field: "customer",
                    sortable: true,
                },
                {
                    label: this.$t("forms.acRegistration"),
                    field: "acRegistration",
                    sortable: false,
                },
                {
                    label: this.$t("forms.arrivalDate"),
                    field: "arrivalDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.serviceRequested"),
                    field: "serviceRequested",
                    sortable: false,
                },
                {
                    label: this.$t("forms.estimatedManHours"),
                    field: "estimatedManHours",
                    sortable: true,
                },
                {
                    label: this.$t("forms.manHours"),
                    field: "manHours",
                    sortable: false,
                },
                {
                    label: this.$t("forms.deadline"),
                    field: "deadline",
                    sortable: true,
                },
                {
                    label: this.$t("forms.lastEditBy"),
                    field: "lastEdit",
                    sortable: false,
                },
                {
                    label: this.$t("forms.status"),
                    field: "status",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage
            };

            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }

            if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)) {
                params['station'] = getUserData()['station']['id']
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });

            this.$Cwos.getCollection({params}, 'cwo_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === "station") {
                this.sortable.order = order + ".name";
            }
            if (order === "customer") {
                this.sortable.order = order + ".customer";
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        statusClass(status) {
            let className = 'primary'
            if (status === 'CLOSED') {
                className = 'success'
            }
            if (status === 'CONFIRMED') {
                className = 'danger'
            }
            if (status === 'DEFERRED') {
                className = 'warning'
            }
            return className
        },
        deadlineColor(deadline) {
            let deadlineClass = "success"
            let diff = new Date(deadline) - new Date();
            let hours = Math.floor(diff / 1000 / 60 / 60);
            if (hours <= 24) {
                deadlineClass = "warning"
            }
            if (hours <= 1) {
                deadlineClass = "danger"
            }
            return deadlineClass
        },
        deleteEmployee(url) {
            this.deleteByUrl(
                this.$Ocrs,
                url,
                this.$t("messages.ocrDeleted"),
                null,
                this.load,
                this.error
            );
        },
        formatHours(data) {
            return data.replace('.',':');
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        enabledChange(enabled) {
            this.enabled = enabled.id;
            if (enabled.id === "all") {
                this.enabled = [true, false];
            }
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
</style>
